import GATSBY_COMPILED_MDX from "/home/runner/work/personal-site-generator/personal-site-generator/blog/start-personal-react-project-2022/index.mdx";
import React from 'react';
import PropTypes from 'prop-types';
import {graphql, Link} from 'gatsby';
import {Helmet} from 'react-helmet';
import {MDXProvider} from '@mdx-js/react';
import Emoji from 'a11y-react-emoji';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import * as SingleBlogStyles from './single-blog.module.scss';
const URI_FRAGMENT = 'blog';
function SingleBlogSeo({pageMeta}) {
  const {title, description, uri, publishedDate, modifiedDate, siteName, siteUrl, image, social} = pageMeta;
  const schema = {
    '@context': 'https://schema.org/',
    '@graph': [{
      '@type': 'BreadcrumbList',
      '@id': siteUrl,
      name: siteName,
      itemListElement: [{
        '@type': 'ListItem',
        position: 1,
        name: 'Blog',
        item: `${siteUrl}/${URI_FRAGMENT}/`
      }, {
        '@type': 'ListItem',
        position: 2,
        name: title
      }]
    }, {
      '@type': 'BlogPosting',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `${siteUrl}/${uri}/`
      },
      headline: title,
      description,
      author: {
        '@type': 'Person',
        name: siteName,
        url: siteUrl,
        sameAs: [social.linkedinUrl, social.githubUrl],
        jobTitle: 'Web Developer'
      },
      image,
      datePublished: publishedDate,
      dateModified: modifiedDate
    }]
  };
  const schemaString = JSON.stringify(schema);
  return React.createElement(Helmet, null, React.createElement("meta", {
    property: "og:type",
    content: "article"
  }), React.createElement("meta", {
    property: "article:author",
    content: siteName
  }), React.createElement("meta", {
    property: "article:published_time",
    content: publishedDate
  }), React.createElement("meta", {
    property: "article:modified_time",
    content: modifiedDate
  }), React.createElement("script", {
    type: "application/ld+json"
  }, schemaString));
}
SingleBlogSeo.propTypes = {
  pageMeta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    publishedDate: PropTypes.string.isRequired,
    modifiedDate: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    social: PropTypes.object.isRequired
  })
};
function SingleBlogPage({pageContext, data, children}) {
  const edge = data.allMdx.edges.find(({node}) => node.id === pageContext.id);
  const post = edge.node;
  const metadata = data.site.siteMetadata;
  const {title, description, publishedDate, modifiedDate, prettyPublishedDate, prettyModifiedDate} = post.frontmatter;
  const pageMeta = {
    title,
    description,
    uri: `${URI_FRAGMENT}/${post.fields.slug}`,
    publishedDate,
    modifiedDate,
    siteName: metadata.title,
    siteUrl: metadata.siteUrl,
    image: metadata.image,
    social: metadata.social
  };
  const hasUpdated = modifiedDate !== publishedDate;
  const shortcodes = {
    Emoji
  };
  return React.createElement(Layout, null, React.createElement(Seo, {
    pageMeta: pageMeta
  }), React.createElement(SingleBlogSeo, {
    pageMeta: pageMeta
  }), React.createElement("article", {
    className: SingleBlogStyles.article
  }, React.createElement("header", null, React.createElement("h1", null, title), React.createElement("p", null, React.createElement("time", {
    dateTime: publishedDate
  }, " Published: ", prettyPublishedDate), hasUpdated && React.createElement(React.Fragment, null, React.createElement("br"), React.createElement("time", {
    dateTime: modifiedDate
  }, " Modified: ", prettyModifiedDate)))), React.createElement(MDXProvider, {
    components: shortcodes
  }, children), React.createElement("footer", null, React.createElement("div", null, edge.previous && React.createElement("article", null, React.createElement("p", null, '<-- Previous post'), React.createElement(Link, {
    to: `/${URI_FRAGMENT}/${edge.previous.fields.slug}/`
  }, React.createElement("h3", null, edge.previous.frontmatter.title)))), React.createElement("div", {
    className: SingleBlogStyles.next
  }, edge.next && React.createElement("article", null, React.createElement("p", null, 'Next post -->'), React.createElement(Link, {
    to: `/${URI_FRAGMENT}/${edge.next.fields.slug}/`
  }, React.createElement("h3", null, edge.next.frontmatter.title)))))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(SingleBlogPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
SingleBlogPage.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___publishedDate, order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      edges {
        previous {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
        node {
          frontmatter {
            title
            description
            publishedDate
            prettyPublishedDate: publishedDate(formatString: "MMM DD, YYYY")
            modifiedDate
            prettyModifiedDate: modifiedDate(formatString: "MMM DD, YYYY")
          }
          fields {
            slug
          }
          body
          id
        }
        next {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
        image
        social {
          linkedinUrl
          githubUrl
        }
      }
    }
  }
`;
